import React, {Component} from 'react';
import CatoButton from "../cato-button";
import './style.scss';


class JoinNetwork extends Component {

    clickEvent = ({label, path}) => {this.props.handleEvent({label, path});}

    render() {
        const {headline, buttonText, buttonLink} = this.props;
        return (
            <div className="join-the-network-wrapper">
                <div>
                    <h3 dangerouslySetInnerHTML={{__html:headline}}/>
                    <CatoButton
                        className="mid yellow-gradient br-26"
                        external={false}
                        path={buttonLink}
                        label={buttonText}
                        onClick={this.props.handleEvent ? this.clickEvent : null}/>
                </div>
            </div>
        )
    }
}

JoinNetwork.defaultProps = {
    headline: '<p>The network you have been waiting for is here.<br />' +
        'Prepare to be <b>amazed.</b></p>',
    buttonText: 'TRY US',
    buttonLink: '/try'
}


export default JoinNetwork;