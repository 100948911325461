import React, {Component} from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import BannerHeadlineTextImage from '../components/banner-headline-text-image';
import Helmet from 'react-helmet';
import JoinNetwork from '../components/join-network';
import PromoFooter from '../components/promo-footer-banner';
import InsightsBanner from '../components/insights-banner';
import YouTubeSection from '../components/youtube-section';
import {videoSectionMapper, mapCoolInsights, mapStickyBanner, mapGartner} from '../common/utils';
import {GTag} from '../common/consts';
import "../assets/styles/pages/_simple-page.scss";

class SimpleTemplate extends Component {

    mapResourcesList = () => {
        return this.props.data.wpPage.resourcesBannerRows.resourcesList.map((item) => {
            return ({
                title: item.title,
                summary: item.resourceFields.resourceLongDescription,
                link: item.resourceFields.resourceLink,
                buttonLabel: item.resourceFields.resourceButtonText,
                img: item.resourceFields.resourceSmallImage ? item.resourceFields.resourceSmallImage.localFile : null
            })
        })
    }

    handleDemoEvent = ({label, path}) => GTag({category: 'Request a Demo', action: 'Button Click', label: path})

    render() {
        const {data} = this.props;
        const {wpPage} = data;
        const {headerWithImage, resourcesBannerRows, tryUsStrip, videoSection} = wpPage;
        const isCareers = wpPage.title === 'Careers';
        const resourcesData = resourcesBannerRows.resourcesList ? this.mapResourcesList() : null;

        const youtube = videoSection && videoSection.videos ? videoSectionMapper(videoSection.videos) : null;

        const coolInsights = mapCoolInsights(wpPage)

        const stickyBanner = mapStickyBanner(wpPage)

        const gartner = mapGartner(wpPage);

        return (
            <Layout
                className="simple-page"
                lang={this.props.pageContext.lang}
                breadcrumbs={[{title: wpPage.title, path: this.props.location.pathname}]}
                whiteMenu={true}
                yoastData={wpPage.seo || null}
                seoTitle={wpPage.pageSeoTitle.pageSeoTitle}
                title={wpPage.title}
                wpid={wpPage.databaseId}
                gartner={gartner}
                seoPath={this.props.location.pathname}
                translations={this.props.pageContext.translations}
                {...{stickyBanner}}>
                {isCareers &&
                    <Helmet>
                        {/*Commet Initialization*/}

                        <script defer={true} type="text/javascript" class="optanon-category-3">{`
                  //this is to work both in prod and staging environments
                  var url = document.location.origin;
                  window.comeetInit = function() {
                  COMEET.init({
                  "token":       "2DCE4C016E01128E4C16E019BC5B85B8",
                  "company-uid": "D2.00C",
                  "company-name":"catonetworks",
                  "font-size":   "13px",
                  //"css-url": url + "/wp-content/themes/cato/css/comeetForm-updated.css"
                  //add more parameters here
              });
              };

                  (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0]; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id;
                  js.src = "//www.comeet.co/careers-api/api.js"; fjs.parentNode.insertBefore(js, fjs);}(document, 'script', 'comeet-jsapi'));
              `}</script>
                    </Helmet>
                }
                <BannerHeadlineTextImage
                    headline={headerWithImage.headerImageTitle}
                    text={headerWithImage.headerImageText}
                    img={headerWithImage.headerImageImage ? headerWithImage.headerImageImage : null}
                />
                {isCareers ?
                    <section className="content" >
                        <div className="careers-content" dangerouslySetInnerHTML={{__html: wpPage.content}} />
                        <script type="comeet-careers"></script>
                    </section>

                    :
                    <section className="content" dangerouslySetInnerHTML={{__html: wpPage.content}}/>

                }
                {resourcesData &&
                <div className={'ebook-section-wrapper'}>
                    <PromoFooter className={"show-small"} data={resourcesData}/>
                </div>}
                <JoinNetwork
                    handleEvent={this.handleDemoEvent}
                    headline={tryUsStrip && tryUsStrip.tryUsHeadingText ? tryUsStrip.tryUsHeadingText : undefined}
                    buttonText={tryUsStrip && tryUsStrip.tryUsButtonText ? tryUsStrip.tryUsButtonText : undefined}
                />
                {youtube &&
                <YouTubeSection
                    headline={videoSection.videoSectionHeadline ? videoSection.videoSectionHeadline : null}
                    videos={youtube}
                />
                }
                {coolInsights &&
                <InsightsBanner
                    {...coolInsights}
                />
                }
            </Layout>
        )
    }
}

export default SimpleTemplate;

export const pageQuery = graphql`
    query simpleTemplatePageQuery($id: String!) {
        wpPage(id: { eq: $id }) {
            ...YoastData
            title
            content
            slug
            id
            databaseId
            date(formatString: "MMMM DD, YYYY")
            path: uri
            pageSeoTitle: pageSeoTitle {
                pageSeoTitle
            }
            headerWithImage {
                headerImageTitle
                headerImageText
                headerImageImage {
                    altText
                    localFile {
                        childImageSharp {
                            fluid(maxWidth: 1040) {
                                ...GatsbyImageSharpFluid_noBase64
                            }
                        }
                        extension
                        publicURL
                    }
                }
            }
            tryUsStrip {
                tryUsHeadingText
                tryUsButtonText
            }
            ...ResourcesBannerRows
            ...StickyBanner
            ...VideoSection
            ...ResourcesTrio
            ...GartnerStrip
    }
}`