import React, {Component, Suspense} from 'react';
// import YouTube from 'react-youtube';
import CatoYoutube from '../cato-youtube';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import {IS_MOBILE, IS_TABLET, ACTION_TYPES, CURR_VID_INIT} from "../../common/consts"
import {GlobalDispatchContext} from "../../context/GlobalContextProvider";
import './style.scss';
import CatoImg from "../cato-image/index";


const width = IS_MOBILE ? '300' : IS_TABLET ? '340' : typeof document !== 'undefined' ? document.documentElement.clientWidth * 0.4427 : 1322;

const height = IS_MOBILE ? '169' : IS_TABLET ? '191' : width / 1.7782;

class YouTubeSection extends Component {

    constructor(props) {
        super(props);

        this.state = {
            imgs: [],
            currentActive: props.videos[0].id,
            play: false
        }
    }

    static contextType = GlobalDispatchContext;

    componentDidMount() {
        window.addEventListener('slick.beforeChange', this.handleSlideChange)
    }

    componentWillUnmount() {
        window.removeEventListener('slick.beforeChange', this.handleSlideChange)
    }

    handleSlideChange = () => {
        if(this.state.play) {
            this.setState({...this.state, play: false});
        }
    };

    switchImgPlayerStates = (elem) => {
        this.setState({...this.state, currentActive: elem, play: !this.state.play})
    };

    handleClick = (e) => {

        const idx = e.target.getAttribute('data-id');
        const vidId = e.target.getAttribute('data-vid-id');
        if(vidId === this.state.currentActive) {
            if(this.props.lightbox) {
                this.handleLightbox({idx, vidId})
            } else {
                this.switchImgPlayerStates(vidId);
            }
        }
        else {
            this.slider.slickGoTo(idx);
            this.setState({...this.state, play: false, currentActive: vidId})
        }
    };

    handleLightbox = ({idx, vidId}) => {
        const dispatch = this.context;

        const vid = this.props.videos[idx];

        dispatch({type: ACTION_TYPES.TOGGLE_HEADER_VIDEO});
        dispatch({type: ACTION_TYPES.CHOOSE_HEADER_VIDEO, payload: {...CURR_VID_INIT, vidId, title: vid.title, subTitle: vid.summary}})
    }

    generateSlides = () => {
        const {videos, lightbox} = this.props;

        return videos.map((vid, i) => (
            <div className="slide" key={`youtube-slide-${i + 1}`} onClick={this.handleClick} data-id={i} data-vid-id={vid.id}>
                <div className={`image-wrapper ${(vid.id === this.state.active) && this.state.play ? 'play' : ''}`} style={{pointerEvents:'none'}}>
                    <CatoImg img={vid.img ? vid.img : `https://i.ytimg.com/vi/${vid.id}/mqdefault.jpg`} id={vid.id} loading={'lazy'} alt=""/>
                </div>
                <div className="text-wrapper" style={{pointerEvents:'none'}}>
                    <div className="bold">{vid.title}</div>
                    <div className="summary">{vid.summary}</div>
                </div>
            </div>
        ))

    };

    generateCarousel = () => {
        const slides = this.generateSlides();

        return (
            <Slider ref={slider => (this.slider = slider)} {...this.props.carouselProps} beforeChange={this.handleSlideChange} className="youtube-slider">
                {slides}
            </Slider>
        )
    };

    generatePlayer = () => {
        const {currentActive} = this.state;

        return (<div className="player">
            <CatoYoutube
              opts={this.props.youtubeProps}
              videoId={currentActive}
            />
            </div>
        )
    }

    render() {
        const {headline} = this.props;
        const {play} = this.state;
        const carousel = this.generateCarousel();
        const player = play ? this.generatePlayer() : null;
        return (
            <section className="youtube-section">
                <h2>{headline}</h2>
                <div className="video-carousel">
                    {carousel}
                    {player}
                </div>
            </section>
        )
    }
}

YouTubeSection.propTypes = {
    headline: PropTypes.string,
    lightbox: PropTypes.bool
};

YouTubeSection.defaultProps = {
    headline: 'Watch Cato SD-WAN in Action',
    lightbox: false,
    carouselProps: {
        dots: false,
        infinite: true,
        arrows: !IS_MOBILE,
        slidesToScroll: 1,
        slidesToShow: 1,
        centerMode: true,
        centerPadding: '25%',
        swipe: true,
        responsive: [{
            breakpoint: 1501,
            settings: {
                centerPadding: '27%'
            }
        },{
            breakpoint: 767,
            settings: {
                centerPadding: '12%',
                centerMode: true,
            }
        }]
    },
    youtubeProps: {
        height,
        width
    }
};

export default YouTubeSection;