import React, {Fragment, useEffect, useState} from 'react';
import {IS_TABLET, IS_MOBILE} from "../../common/consts";
import CatoLink from '../cato-link';
import CatoButton from '../cato-button';
import {truncate} from 'lodash';
import './style.scss';


const PromoFooter = ({className='', data}) => {

    const [isMobile, setIsMobile] = useState(false);
    const [isTablet, setIsTablet] = useState(false);

    useEffect(() => {
        if (IS_MOBILE) setIsMobile(true);

        if (IS_TABLET) setIsTablet(true);
    }, []);

    const summaryCutOff = isTablet ? 200 : isMobile ? 100 : 240;

    return (
        <Fragment>
            <section className={`section-ebook ${className}`}>
                <CatoLink path={data[0].link} className={'ebook-wrapper'}>
                    <div className="img-wrapper" style={
                        data[0].img ?
                        {backgroundImage: `url(${data[0].img.publicURL || data[0].img})`}
                        : null
                    }/>
                    <div>
                        {data[0].title && <h1>{data[0].title}</h1>}
                        {data[0].summary && <span>{truncate(data[0].summary, {length: summaryCutOff, omission: '...'})}</span>}
                        {data[0].link && <CatoButton className="resources-green-button" type="button" label={data[0].buttonLabel}/>}
                    </div>
                </CatoLink>
            </section>
            {data[1] && data[1] !== undefined &&
                <section className={`section-ebook ${className}`}>
                    <CatoLink path={data[1].link} className={'video-wrapper'}>
                        <div className={'what-to-consider'}>
                            {data[1].title && <h1>{data[1].title}</h1>}
                            {data[1].link && <CatoButton className="resources-green-button" type="button" label={data[1].buttonLabel}/>}
                        </div>
                        <div className="video-green-bg img-wrapper" style={{backgroundImage: `url(${data[1].img.publicURL || data[1].img})`}}/>
                    </CatoLink>
                </section>
            }
        </Fragment>
    )
};

export default PromoFooter;