import React, {useState, useEffect} from 'react';
import {Link} from 'gatsby';
import CatoImg from "../cato-image";
import Slider from "react-slick/lib";
import './style.scss';
import arrowLeft from '../../assets/images/nav arrow left.svg';
import arrowRight from '../../assets/images/nav arrow right.svg';
import {IS_MOBILE, IS_DESKTOP, IS_TABLET} from '../../common/consts.js';

const SmartLink = ({children, link = ''}) => {
    let extLink = false;
    if(typeof window !== 'undefined') {
        const linkHost = link[0] !== '/' ? link.match(/https:\/\/www.catonetworks.com/i) : false;
        extLink = !linkHost;
    }
    return <a href={link} target="_blank" rel="noopener noreferrer">{children}</a>;
       // <Link to={link}>{children}</Link>
};

const Block = ({image, title, link, className}) => (
    <>
    {image && <div className="image-wrapper"><CatoImg img={image} alt=""/></div>}
        <div className="text-wrapper">
            {title && <div className="title">{title}</div>}
            {link.text && <div className={`cato-button oval green cool-insights-button ${className && className.includes('blocks') ? 'br-4 green-outline' : ''}`}>{link.text}</div>}
        </div>
    </>
);

const InsightsBanner = ({headline, data, className, navArrows, slidesToShow, slideSettings}) => {
    const items = data.map((item,i) => <SmartLink key={`banner-item-${i}`} link={item.link && item.link.path}><Block className={className} {...item} /></SmartLink>);
    const [isDesktop, setDesktop] = useState(true);
    const [isTablet, setTablet] = useState(false);

    useEffect(() => {
        if(!IS_DESKTOP) {
            setDesktop(false);
        }

        if(IS_TABLET) {
            setTablet(true)
        }
    })

    return (
        <section className={`insights-banner-wrapper ${className}`}>
            <h2>{headline}</h2>
            <div className="banner-items hide-bullets-style">
                {isDesktop && <div className={'desktop'}>{items}</div>}
                {!isDesktop &&
                <Slider
                    nextArrow={<span><img src={arrowRight} alt="Next"/></span>}
                    prevArrow={<div><img src={arrowLeft} alt="Previous"/></div>}
                    className="carousel"
                    arrows={navArrows}
                    slidesToShow={slidesToShow}
                    {...slideSettings}
                >{items}</Slider>}
            </div>
        </section>
    );
}

InsightsBanner.defaultProps = {
    headline: 'Cool Insights, Latest Updates',
    data: [],
    className: '',
    slidesToShow: 1,
    navArrows: true,
    slideSettings: {}
};

export default InsightsBanner;
